/**
 * Helper, um ein Bild aus einem GraphQL-Set per Dateiname zu bekommen
 * @param {Array} images Array aus der Query
 * @param {String} path Pfad des gewünschten Bildes
 * @returns {childImageSharp|undefined} Bild oder nix
 */
export const getImageFromNode = (images, path) =>
    images.edges.find(({ node }) => node.relativePath === path);

/**
 * Überprüft, welchen Typ ein Link hat
 * @param {string} to Der Link
 * @return {string} Typ des Links (external, internal oder anchor)
 */
export const getLinkType = to => {
    // Überprüft, ob Link mit / startet oder mit .pdf endet bzw. mit /flippingbook startet.
    // Diese Links müssen wie externe behandelt werden
    const internal = /^\/(?!\/)/.test(to) && !to.endsWith('.pdf');
    const anchor = to && to.startsWith('#');
    const mailOrPhone = to && (to.startsWith('tel:') || to.startsWith('mailto:'));
    let type = 'external';

    // Interner (Gatsby-Link)
    if (internal && !anchor) {
        type = 'internal';
    } else if (!internal && anchor) {
        type = 'anchor';
    } else if (!internal && mailOrPhone) {
        type = 'mailOrPhone';
    }
    return type;
};

/**
 * Führt eine Media Query aus und informiert optional einen Callback bei Änderungen.
 * @param  {String} query CSS Style Media Query: (min-width: 992px)
 * @param  {Function} callback (optional) Callback wird bei Änderungen informiert
 * @returns {Boolean|undefined} Wenn kein Callback übergeben wird, wird sofort das Ergebnis zurückgeliefert
 */
export function matchMedia(query, callback) {
    const res = window.matchMedia(query);

    if (!callback) {
        return res.matches;
    }

    callback(res.matches, res.media);

    return res.addListener(changed => {
        callback(changed.matches, changed.media);
    });
}

/**
 * Findet Object mit speziellem Wert innerhalb eines Array von Objects
 * @param {array} array Das zu durchsuchende Array
 * @param {string} key Key
 * @param {string/number} key Value, der verglichen wird
 */
export const getObjectByValue = (array, key, value, firstValueOnly) => {
    const result = array.filter(object => object[key] === value);
    return firstValueOnly ? result[0] : result;
};

/**
 * Loggt in die Browser-Konsole, wenn sie verfügbar ist
 * Logging wird in "production" nicht ausgeführt
 * @param  {...any} args Argumente die geloggt werden sollen
 */
export function log(...args) {
    if (
        typeof window !== 'undefined' &&
        window.console &&
        window.console.log &&
        typeof window.console.log === 'function' &&
        process.env.NODE_ENV === 'development'
    ) {
        window.console.log(...args);
    }
}

/**
 * Scrollt zu Event Target oder DOM Knoten, je nachdem wie aufgerufen wird
 * @param {Object} elOrEvt Event oder DOM-Knoten
 * @example <div onClick={scrollTo} href="#foo" /> oder scrollTo(document.getElementById(...))
 */
export const scrollTo = (elOrEvt, scrollOffset) => {
    let element;
    let offset = scrollOffset || 0;
    if (elOrEvt.currentTarget) {
        elOrEvt.preventDefault();
        elOrEvt.currentTarget.blur();
        const id = elOrEvt.currentTarget.getAttribute('href').substring(1);
        element = document.getElementById(`${id}`);
        offset = elOrEvt.currentTarget.getAttribute('data-scroll-offset');
    } else {
        element = elOrEvt;
    }

    if (element) {
        window.setTimeout(() => {
            window.scroll({
                top: element.getBoundingClientRect().top + window.pageYOffset - offset,
                behavior: 'smooth',
            });
        }, 0);
    }
};

export default scrollTo;
