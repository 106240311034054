import { createGlobalStyle } from 'styled-components';
import { normalize, rem } from 'polished';

import { mq } from '../helpers/stylehelpers';
import { fontSizes, fontFamilies, colors } from '../helpers/variables';

import latoRegularEot from '../fonts/lato-regular.eot';
import latoRegularWoff2 from '../fonts/lato-regular.woff2';
import latoRegularWoff from '../fonts/lato-regular.woff';
import latoRegularTtf from '../fonts/lato-regular.ttf';

import latoLightEot from '../fonts/lato-light.eot';
import latoLightWoff2 from '../fonts/lato-light.woff2';
import latoLightWoff from '../fonts/lato-light.woff';
import latoLightTtf from '../fonts/lato-light.ttf';

const GlobalStyles = createGlobalStyle`

    ${normalize()}
    @font-face {
        font-family: "Lato";
        font-style: normal;
        font-weight: normal;
        font-display: fallback;
        src:url(${`${latoRegularEot}#iefix`});
        src:url(${`${latoRegularEot}#iefix`}) format("eot"),
        url(${`${latoRegularWoff2}`}) format("woff2"),
        url(${`${latoRegularWoff}`}) format("woff"),
        url(${`${latoRegularTtf}`}) format("truetype");
    }

    @font-face {
        font-family: "Lato";
        font-style: normal;
        font-weight: 300;
        font-display: fallback;
        src:url(${`${latoLightEot}#iefix`});
        src:url(${`${latoLightEot}#iefix`}) format("eot"),
        url(${`${latoLightWoff2}`}) format("woff2"),
        url(${`${latoLightWoff}`}) format("woff"),
        url(${`${latoLightTtf}`}) format("truetype");
    }

    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    :root {
        /* Font-Sizes */
        --fs-base: ${rem(18)};

        /* Font-Sizes */
        --fs-h1: ${rem(40)};
        --fs-h2: ${rem(35)};
        --fs-h3: ${rem(25)};
        --fs-h4: ${rem(22)};
        --fs-h5: ${rem(20)};
        --fs-h6: ${rem(18)};

        --fs-xxxxl: ${rem(65)};
        --fs-xxxl: ${rem(50)};
        --fs-xxl: ${rem(40)};
        --fs-xl: ${rem(30)};
        --fs-l: ${rem(22)};
        --fs-m: ${rem(20)};
        --fs-s: ${rem(18)};
        --fs-xs: ${rem(14)};

        /* Gaps */
        --gap-s: ${rem(4)};
        --gap-m: ${rem(8)};
        --gap-l: ${rem(16)};
        --gap-xl: ${rem(24)};
        --gap-xxl: ${rem(32)};
        --gap-xxxl: ${rem(48)};
        --gap-xxxxl: ${rem(60)};

        ${mq.large`
            --fs-base: ${rem(20)};
            --fs-m: ${rem(20)};
        `};

        ${mq.xlarge`
            --fs-base: ${rem(22)};
            --fs-m: ${rem(22)};
        `};

    }

    html, body {
        height: 100%;
        -webkit-overflow-scrolling: auto;
        color: ${colors.textColor};
    }

    body {
        ${fontSizes.base}
        font-family: ${fontFamilies.default};
        font-weight: 300;
        line-height: 1.5;
        /* overflow: hidden; */

        ${mq.mediumDown`
            &.off-canvas-active {
                overflow: hidden;
            }
        `};

        &.modal-active {
            overflow: hidden;
        }

    }

    h1, h2, h3, h4, h5, h6, strong, b {
        font-weight: normal;
    }

    address {
        font-style: normal;
    }

    ul {
        margin-top: 0;
    }

    hr {
        border: 1px solid ${colors.gray300};
        border-bottom: none;
        margin: 1em 0;

        ${mq.medium`
            margin: 2em 0;
        `};
    }

    body > div, #___gatsby {
        overflow-x: hidden;
    }
`;

export default GlobalStyles;
