import React from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { position, rem, rgba } from 'polished';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Button from '../components/Button';
import logoSrc from '../images/logo.svg';

import { colors } from '../helpers/variables';
import { pseudoStateClasses, mq } from '../helpers/stylehelpers';

/** Logo */
const Logo = styled.img`
    max-width: ${rem(200)};
    display: block;
    margin: 0 auto ${rem(20)};
    flex-shrink: 0;

    ${mq.medium`
        margin-bottom: ${rem(30)};
        max-width: ${rem(260)};
    `};

    ${mq.large`
        max-width: ${rem(280)};
        margin-bottom: ${rem(45)};
    `};

    ${mq.xlarge`
        max-width: ${rem(300)};
        margin-bottom: ${rem(55)};
    `};
`;

/** Full-Width BG */
const Background = styled(Img)`
    pointer-events: none;
    height: 100%;
    height: 100vh;
    width: 100%;
    width: 100vw;
`;

/** Content */
const Content = styled.div`
    ${position('absolute', '50%', null, null, '50%')};
    align-items: center;
    display: flex;
    justify-content: center;
    color: ${colors.white};
    height: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    padding: ${rem(20)};
    text-align: center;
    transform: translate(-50%, -50%);
    width: 100%;
    width: 100vw;
`;

const ContentInner = styled.div`
    margin: auto;
    max-width: ${rem(600)};
    padding-bottom: ${rem(30)};

    ${mq.large`
        max-width: ${rem(640)};
    `};

    ${mq.xlarge`
        max-width: ${rem(720)};
    `};
`;

/** Info-Text */
const P = styled.p`
    text-shadow: 0 1px 1px ${rgba(colors.black, 0.8)};
    line-height: 1.6;
    margin: 1em 0;
`;

/** Wrapper um Links */
const LinkWrapper = styled.div`
    margin-bottom: ${rem(20)};

    ${mq.medium`
        margin-bottom: ${rem(30)};
    `};

    ${mq.large`
        margin-bottom: ${rem(40)};
    `};

    ${mq.xlarge`
        margin-bottom: ${rem(55)};
    `};
`;

/** Headline über den Links */

const LinkTitle = styled.strong`
    margin-bottom: 0.25em;
`;

/* Shared Link Styles */
const linkStyles = css`
    color: ${colors.white};
    text-decoration: none;
    transition: color 0.25s;
    display: block;

    ${pseudoStateClasses`
        color: ${colors.primary};
        text-decoration: underline;
    `};
`;

/** Mail + URL */
const Link = styled.a`
    ${linkStyles};
    color: ${colors.white};
    text-decoration: none;
    transition: color 0.25s;
    display: block;
`;

/** Link zum Impressum */
const LegalLink = styled(GatsbyLink)`
    ${linkStyles};
    margin-top: ${rem(40)};
    font-size: 80%;
`;

/**
 * Die Komponente der Startseite
 * @param {Object} data Daten aus der GraphQL-Query
 */
const StartPage = ({ data: { bgImage } }) => {
    return (
        <Layout
            title="Schönheit | Vitalität | Wohlbefinden - Startseite"
            description="Manuela Costanzo | Schönheit | Vitalität | Wohlbefinden"
        >
            <Background tag="section" fluid={bgImage.childImageSharp.fluid} />
            <Content>
                <ContentInner>
                    <Logo src={logoSrc} />
                    <P>
                        Körperbehandlungen, Ernährungsberatung und Vitalstoffberatung – ein
                        ganzheitliches Body-Forming-Konzept.
                    </P>
                    <P>
                        Modellierende, glättende und straffende Körperbehandlungen stimulieren
                        körpereigene Stoffe, um Ihre Problemzonen nachhaltig abzubauen und um ihrem
                        Gesicht ein frischeres Aussehen zu verleihen.
                    </P>
                    <P>
                        100% natürliche Behandlungsmethoden, welche für ein vitaleres Körpergefühl
                        sorgen.
                    </P>
                    <P>Für Männer und Frauen geeignet.</P>
                    <LinkTitle>Weitere Informationen unter:</LinkTitle>
                    <LinkWrapper>
                        <Link href="tel:01522 3558495">01522 3558495</Link>
                    </LinkWrapper>
                    <Button to="mailto:info@manuela-costanzo.de" ghost>
                        Termin vereinbaren
                    </Button>
                    <LegalLink to="impressum-datenschutz">Impressum & Datenschutz</LegalLink>
                </ContentInner>
            </Content>
        </Layout>
    );
};

StartPage.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
};

export default StartPage;

/**
 * Query für benötigte Assets der Seite
 * @type {GraphQL}
 */
export const query = graphql`
    {
        bgImage: file(relativePath: { glob: "bg.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2560, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
