import { css } from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from './variables';

/**
 * Mixin, um Pixelwert zu VW-Wert (basierend auf einem "Designviewport" von 1920px zu konvertieren
 * @param  {Number} pxValue Beliebige Pixelzahl
 * @return {String}
 */
export const vwCalc = (pxValue, designViewport = 1920) => {
    const base = 100 / designViewport;
    return `${pxValue * base}vw`;
};

/**
 * Mixin um Pixel zu REM zu konvertieren
 * @param  {Array} values Beliebige Anzahl von Zahlen
 * @return {String}
 * @example
 *     padding: ${remCalc(5, 20, 0)}; ===> padding: 0.3125rem 1.25rem 0;
 */
export const remCalc = (...values) =>
    values
        .map(value => {
            const numVal = parseInt(value, 10);

            return numVal === 0 ? '0' : `${numVal / 16}rem`;
        })
        .join(' ');

/**
 * Mixin um zwischen zwei Breakpoints Pixelwerte fließend in Abhängigkeit des Viewports zu skalieren
 * @param  {String} cssProp CSS Propterty {String}
 * @param  {String} minxPxValue minimaler Pixelwert
 * @param  {String} maxPxValue maximaler Pixelwert
 * @param  {String} minViewportWidth minimaler Viewport, untere Grenze
 * @param  {String} maxViewportWidth maximaler Viewport, obere Grenze
 * @param  {boolean} noMobileMQ Name ist Programm
 * @param  {boolean} usePixel Pixel statt REM
 * @param  {boolean} important Name ist Programm
 * @return {String}
 * @example
 *         ${vwHelper('font-size', 18, 22, 320, 1920)};
 */
export const vwMinMax = (
    cssProp,
    minPxValue,
    maxPxValue,
    minViewport = breakpoints.small,
    maxViewport = breakpoints.xxxlarge,
    noMobileMQ = false,
    usePixel,
    important
) => {
    const vwOrVh = cssProp === 'top' || cssProp === 'bottom' ? 'vh' : 'vw';
    const widthOrHeight = cssProp === 'top' || cssProp === 'bottom' ? 'height' : 'width';

    // Viewportgrößen von ggf. vorhanden REMs befreien
    let minViewportInPixelUnitless = minViewport;
    let maxViewportInPixelUnitless = maxViewport;

    if (typeof minViewport === 'string') {
        minViewportInPixelUnitless = minViewport.indexOf('rem') && minViewport.split('rem')[0] * 16;
    }

    if (typeof maxViewport === 'string') {
        maxViewportInPixelUnitless = maxViewport.indexOf('rem') && maxViewport.split('rem')[0] * 16;
    }

    const viewportIncrease = maxViewportInPixelUnitless - minViewportInPixelUnitless;

    const minMax = (min, max) => {
        const increase = max - min;
        return `
            calc(${(increase / viewportIncrease) * 100}${vwOrVh} + ${min -
            (increase / viewportIncrease) * minViewportInPixelUnitless}px)
        `;
    };

    let maxValue = usePixel ? `${maxPxValue}px` : rem(maxPxValue);
    let minValue = usePixel ? `${minPxValue}px` : rem(minPxValue);
    let minMaxValue = minMax(minPxValue, maxPxValue);

    // Wenn es multiple Werte sind, ggf. für padding etc.
    if (Array.isArray(minPxValue) && Array.isArray(maxPxValue)) {
        maxValue = usePixel
            ? maxPxValue.map(value => `${value}px`).join(' ')
            : maxPxValue.map(value => rem(value)).join(' ');

        minValue = usePixel
            ? minPxValue.map(value => `${value}px`).join(' ')
            : minPxValue.map(value => rem(value)).join(' ');

        minMaxValue = minPxValue.map((value, index) => minMax(value, maxPxValue[index])).join(' ');
    }

    const mobileMQ = !noMobileMQ ? `${cssProp}: ${minValue}${important ? ' !important' : ''};` : '';

    return `

       ${mobileMQ};

        @media (min-${widthOrHeight}: ${rem(minViewportInPixelUnitless)}) {
            ${cssProp}: ${minMaxValue}${important ? ' !important' : ''};
        }

        @media (min-${widthOrHeight}: ${rem(maxViewportInPixelUnitless)}) {
            ${cssProp}: ${maxValue}${important ? ' !important' : ''};
        }
    `;
};

/**
 * Helper um Code in Pseudo-Klassen für Hover etc. zu wrappen
 * @param  {String} args Zu wrappendes CSS
 * @return {String}      Gewrapptes CSS
 */
export const pseudoStateClasses = (...args) => css`
    &:hover,
    &:focus,
    &:active {
        ${css(...args)};
    }
`;

/**
 * Mixin, um wiederverwertbare MediaQueries zu erhalten
 * @param  {Object} mq Beliebige Anzahl von Zahlen
 * @return {String} In MQ gewrapptes CSS
 * @example
 *     font-size: 16px;
 *     ${mq.medium`font-size: 20px`};
 */
export const mq = {
    smallOnly: (...args) => css`
        @media (max-width: ${breakpoints.mediumDown}) {
            ${css(...args)};
        }
    `,
    medium: (...args) => css`
        @media (min-width: ${breakpoints.medium}) {
            ${css(...args)};
        }
    `,
    mediumDown: (...args) => css`
        @media (max-width: ${breakpoints.largeDown}) {
            ${css(...args)};
        }
    `,
    mediumOnly: (...args) => css`
        @media (min-width: ${breakpoints.medium}) and (max-width: ${breakpoints.largeDown}) {
            ${css(...args)};
        }
    `,
    large: (...args) => css`
        @media (min-width: ${breakpoints.large}) {
            ${css(...args)};
        }
    `,
    largeDown: (...args) => css`
        @media (max-width: ${breakpoints.xlargeDown}) {
            ${css(...args)};
        }
    `,
    largeOnly: (...args) => css`
        @media (min-width: ${breakpoints.large}) and (max-width: ${breakpoints.xlargeDown}) {
            ${css(...args)};
        }
    `,
    xlarge: (...args) => css`
        @media (min-width: ${breakpoints.xlarge}) {
            ${css(...args)};
        }
    `,
    xlargeDown: (...args) => css`
        @media (max-width: ${breakpoints.xxlargeDown}) {
            ${css(...args)};
        }
    `,
    xlargeOnly: (...args) => css`
        @media (min-width: ${breakpoints.xlarge}) and (max-width: ${breakpoints.xxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxlarge: (...args) => css`
        @media (min-width: ${breakpoints.xxlarge}) {
            ${css(...args)};
        }
    `,
    xxlargeDown: (...args) => css`
        @media (max-width: ${breakpoints.xxxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxlargeOnly: (...args) => css`
        @media (min-width: ${breakpoints.xxlarge}) and (max-width: ${breakpoints.xxxlargeDown}) {
            ${css(...args)};
        }
    `,
    xxxlarge: (...args) => css`
        @media (min-width: ${breakpoints.xxxlarge}) {
            ${css(...args)};
        }
    `,
};

/**
 * Exportiert globales Padding für Elemente die auf den "Seitenrand" laufen
 * z.B. Footer, Header, Stage etc.
 */
export const globalFramePadding = () => css`
    padding-left: ${rem(15)};
    padding-right: ${rem(15)};

    ${mq.large`
        padding-left: ${rem(40)};
        padding-right: ${rem(40)};
    `};

    ${mq.xxlarge`
        padding-left: ${rem(60)};
        padding-right: ${rem(60)};
    `};

    ${mq.xxxlarge`
        padding-left: ${rem(80)};
        padding-right: ${rem(80)};
    `};
`;
