import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import GlobalStyles from './GlobalStyles';

/**
 * Rendert ein Standard-Layout
 * @param {Node} props.children Der Inhalt
 * @param {string} props.title Der Seitentitel
 * @param {string} props.description Die Meta-Description
 */

const Layout = ({ children, title, description }) => (
    <>
        <Helmet
            title={`Manuela Costanzo - ${title}`}
            meta={[{ name: 'description', content: description }]}
            htmlAttributes={{
                lang: 'de',
            }}
        />
        <GlobalStyles />
        {children}
        {/* eslint-disable react/no-danger */}
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
                __html: `
                            {
                                "@context": "http://schema.org",
                                "@type": "Organization",
                                "name": "Manuela Costanzo",
                                "description": "Manuela Costanzo: Schönheit | Vitalität | Wohlbefinden Wohlbefinden",
                                "logo": "https://manuela-costanzo.de/logo.png",
                                "url": "https://manuela-costanzo.de",
                                "telephone": "+4915223558495",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "Karl-Krische-Straße 4",
                                    "addressLocality": "Backnang",
                                    "postalCode": "71522",
                                    "addressCountry": "Germany"
                                }
                            }
                        `,
            }}
        />
        {/* eslint-enable react/no-danger */}
    </>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    description: PropTypes.string,
    title: PropTypes.string.isRequired,
};

Layout.defaultProps = {
    description: '',
};

export default Layout;
